import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <h1>Page not found</h1>
      <p>Please try going back to the home page</p>
    </div>
  </Layout>
)

export default NotFoundPage
